/* Global CSS */

body {
  margin: 0;
  font-family:"Barlow Condensed", sans-serif;
  -webkit-font-smoothing: "Barlow Condensed", sans-serif;
  -moz-osx-font-smoothing: "Barlow Condensed", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: #5e3bee;
  --heading-color: #282938;
  --bg-shade: #bcc0c1;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
}
.btn {
  font-family: "Barlow Condense";
  display: inline-block;
  padding: 14px 32px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}
.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}
.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}
.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.skills--section--card {
  flex-basis: 300px; /* Fixed size for each card */
  max-width: 300px; /* Prevents cards from growing too large */
  height: 250px; /* Ensures all cards have the same height */
  padding: 20px;
  background-color: var(--card-bg-color); /* Use your preferred background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure spacing between title and description */
  border-radius: 8px;
  box-sizing: border-box; /* Includes padding and border in the element’s width and height */
  overflow: hidden; /* Ensures overflow is clipped */
}

.skills--section--title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  white-space: nowrap; /* Ensures the title stays on one line */
  overflow: hidden; /* Hides text overflow */
  text-overflow: ellipsis; /* Adds '...' to truncated text */
}

.skills--section--description {
  font-size: 14px;
  text-align: center;
  flex-grow: 1; /* Allows description to grow, but within limits */
  overflow: hidden; /* Prevents overflow of text */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits the number of lines */
  -webkit-box-orient: vertical;
}

/* Container Flex adjustments */
.skills--section--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between cards */
  align-items: stretch; /* Ensures cards stretch equally in height */
}

/* Navbar Style Start */
/* Navbar Style Start */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 85.333px;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;  /* Ensures the navbar stays above other elements */
}

.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.navbar--items ul > li > a {
  text-decoration: none;
}

/* Navbar Content */
.navbar--content {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.navbar--active-content {
  color: var(--primary);
}

/* Navbar Style End */

/* Navbar Styler Ends */

/* Hero Section Style */

/* Updated Button Styles for Lower Position */
.hero--buttons {
  position: relative;  /* Allows you to move the buttons relative to their container */
  top: 70px; /* Pushes the buttons lower on the page */
  z-index: 1;
}

.hero--resume-btn {
  font-size: 24px; /* Make the resume button larger */
  padding: 18px 40px;
  margin-bottom: 16px;
  color: black;  /* Text color */
  background-color: white;  /* Background color */
  border: 2px solid black;  /* Border color to match text */
  transition: background-color 0.3s ease;
}
.hero--resume-btn:hover {
  background-color: #4a4a4a;  /* Dark grey background on hover */
  color: white;  /* Change text to white on hover for better contrast */
}
.hero--social-buttons {
  display: flex;
  gap: 65px;
  margin-top: 40px; /* Adds space between the large resume button and small buttons */
}

.hero--social-btn {
  font-size: 20px; /* Make the social buttons smaller */
  padding: 30px 80px;
}

.hero--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 133.333px 85.333px 133.333px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-color: var(--bg-shade);
}
.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
}
.hero--section--content--box > button {
  margin-top: 21.333px;
}

.hero--section--title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
}
.hero--section--title--color {
  color: var(--primary);
}
.hero--section-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}
.hero--section--img {
  display: flex;
}
.hero--section--img > img {
  width: 100%;
  height: 100%;
}
/* Hero Section Style Ends */

/* Skills Section Style */
.skills--section {
  display: flex;
  padding: 149.33px 85.33px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 106.667px;
}
.skills--section--heading {
  color: var(--heading-color);
  font-size: 64px;
  font-weight: 700;
  line-height: 77px;
}
.skills--section--container {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  gap: 42.6px;
  grid-template-columns: repeat(4, 1fr);
}
.skills--section--card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--bg-shade);
  min-height: 250px;
}
.skills--section--card:hover {
  border-bottom: 4px solid var(--primary);
}
.skills--section--card:hover .skills--section--description {
  color: var(--darkblue);
}
.skills--section--img {
  display: flex;
  padding: 13.3px;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}
.skills--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.skills--section--title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}
.skills--section--description {
  color: var(--black);
  font-size: 21.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
/* Skills Style Ends */

/* About Us */
.about--section {
  display: grid;
  padding: 133.3px 85.3px;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
}
.about--section--img > img {
  width: 100%;
  height: 100%;
}
/* About Us Ends */

/* My Portfolio Starts */
.portfolio--section {
  display: flex;
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}

.portfolio--container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.portfolio--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}

.portfolio--section--container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; /* Aligns the items from the top to ensure uniformity */
  justify-content: space-between;
  gap: 42.6px;
  width: 100%;
}

.portfolio--section--img {
  border-radius: 8px;
  width: 100%;
  flex-grow: 0; /* Ensures the image doesn't grow disproportionately */
}

.portfolio--section--img > img {
  width: 100%;
  height: auto; /* Keeps images responsive and proportional */
  border-radius: 8px;
}

.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 0 1 calc(33.333% - 42.6px); /* Ensures 3 items per row, accounting for gap */
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 42.6px;
  
  /* Ensure all cards have the same height */
  align-self: stretch;
}

.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  
  /* Ensures the content takes up available space */
  flex-grow: 1;
  align-self: stretch;
}

.portfolio--section--title {
  color: var(--heading-color);
}

.portfolio--link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}

.portfolio--section--card:hover path {
  stroke: #006b6a;
}
.portfolio-link {
  color: black;          /* Keeps the link text color black */
  text-decoration: none; /* Removes underline */
  font-weight: 600;      /* Keeps it bold as per your original design */
}

.portfolio-link:hover {
  color: #006b6a;        /* Optional: Change the color on hover */
}
/* Portfolio Ends */

/* Testimonial */
.testimonial--section {
  display: flex;
  padding: 112px 84.3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background-color: var(--bg-shade);
}
.sections--heading {
  color: var(--heading-color);
}
.testimonial--section--card {
  display: flex;
  padding: 42.6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 42.6px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1.33px solid #006b6a;
}
.testimonial--section--card--reviews {
  display: flex;
  align-items: flex-start;
  gap: 5.33px;
}
.testimonial--section--card--author--detail {
  display: flex;
  align-items: center;
  gap: 21.3px;
}
.testimonial--author--name {
  font-weight: 600;
  color: var(--heading-color);
}
.testimonial--author--designation {
  color: var(--darkblue);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
/* Testimonial Ends */
.contact--section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}
.checkbox--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10.6px;
  flex: 1 0 0;
  justify-content: flex-start;
}
input[type="checkbox"] {
  width: 21px;
  height: 21px;
  border: 0.15em solid var(--heading-color);
  border-radius: 0.15em;
  transform: translatey(-0.075em);
}
/* Contact End */

/* Footer Start */
/* Footer Starts */
.footer--container {
  display: flex;
  padding: px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 22px;
    line-height: 70px;
  }
  .skills--section--title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills--section--heading {
    font-size: 54px;
    line-height: 70px;
  }
  .skills--section--container {
    gap: 16px;
  }
  .skills--section--card {
    gap: 28px;
  }
  .skills--section--card--content {
    gap: 20px;
  }
  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-outline-primary {
    display: none;
  }
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
  }
  .skills--section--container,
  .about--section,
  .portfolio--section--container,
  .portfolio--container-box,
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  .skills--section,
  .portfolio--container,
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .portfolio--container-box {
    gap: 30px;
  }
  .skills--section,
  .testimonial--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .about--section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .testimonial--section--card {
    padding: 25px;
  }
  .hero--section--title,
  .skills--section--heading,
  .sections--heading {
    font-size: 20px;
    line-height: 40px;
  }
  .hero--section--content--box {
    gap: 10px;
  }
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }
}

/* Hamburger menu  */

.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}

/* Responsive Screens Ends*/
